<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">Buy Gift Card Transaction Details</h2>
        </div>

        <div class="btn-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            @click="RefreshData"
          >
            Reload
          </button>
        </div>
      </div>
      <div v-if="data" class="table-settings mb-4">
        <div class="d-flex align-items-center justify-content-between">
          <div class="">
            <button
              type="button"
              class="btn btn-sm btn-success"
              :disabled="data.status !== 'PROCESSING'"
              @click="
                RequestConfirmation(
                  'Do you really want to approve this transaction?',
                  () => {
                    RequestConfirmation(
                      'Sure to approve this transaction? This is an irreversible action',
                      ApproveTransaction
                    );
                  }
                )
              "
            >
              Approve Transaction
            </button>
          </div>
          <div class="">
            <button
              type="button"
              class="btn btn-sm btn-secondary text-nowrap"
              :disabled="data.status !== 'SUBMITTED'"
              @click="
                RequestConfirmation(
                  'Do you really want to start processing this transaction?',
                  () => {
                    RequestConfirmation(
                      'Sure to set transaction status to processing? This is an irreversible action',
                      ProcessTransaction
                    );
                  }
                )
              "
            >
              Process Transaction
            </button>
          </div>
          <div class="">
            <button
              type="button"
              class="btn btn-sm btn-danger text-nowrap"
              :disabled="data.status !== 'PROCESSING'"
              @click="
                RequestConfirmation(
                  'Do you really want to decline this transaction?',
                  () => {
                    RequestConfirmation(
                      'Sure to decline this transaction? This is an irreversible action',
                      DeclineTransaction
                    );
                  }
                )
              "
            >
              Decline Transaction
            </button>
          </div>
        </div>
      </div>
      <div v-if="data" class="row">
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h4 class="text-gray">Reference</h4>
              <h5 class="fw-bolder">{{ data.reference }}</h5>
            </div>

            <div class="row justify-content-center text-center py-3">
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Status</h4>
                <h6>{{ data.status }}</h6>
              </div>
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Rate</h4>
                <h6>{{ data.rate }}</h6>
              </div>
              <div class="col-6 col-md-4">
                <h4 class="text-gray">Card Slug</h4>
                <h6>{{ data.card_slug }}</h6>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-4">
                <h4 class="text-gray">UserId</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>{{ data.User.id }}</h6>
                </router-link>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Email</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>{{ data.User.email }}</h6>
                </router-link>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Name</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>
                    {{ data.User.Profile.first_name }}
                    {{ data.User.Profile.last_name }}
                  </h6>
                </router-link>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Comment</h4>
                <h6>
                  {{ data.description ? data.description : "No Description" }}
                </h6>
              </div>
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Remark</h4>
                <h6>{{ data.remark ? data.remark : "No Remark" }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5 mt-md-0">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Gift Card Currency</h6>
            </div>

            <div class="row text-center py-3">
              <div class="col-4">
                <h4 class="text-gray">Code</h4>
                <h6>{{ data.FiatCurrency.code }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Name</h4>
                <h6>{{ data.FiatCurrency.name }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Rate</h4>
                <h6>{{ data.FiatCurrency.rate }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12">
          <h1 class="text-danger text-center">No Data!</h1>
        </div>
      </div>
    </section>
  </HourglassLoader>
</template>

<script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { onBeforeMount, ref, defineComponent, inject } from "vue";
import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";

export default defineComponent({
  name: "BuyGiftCardDetail",
  components: {
    HourglassLoader,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const RequestConfirmation = inject("RequestConfirmation");

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/cards/buy/transactions/${route.params.transactionId}`
        );
        data.value = response.data.data.transaction;
        Toast.fire({
          icon: "success",
          title: "Transaction Detail Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ApproveTransaction = async () => {
      loading.value = true;
      try {
        const response = await apiPost(
          `/admin/cards/buy/transactions/${route.params.transactionId}/status`,
          { status: "DONE" }
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Approved Successfully",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ProcessTransaction = async () => {
      loading.value = true;
      try {
        const response = await apiPost(
          `/admin/cards/buy/transactions/${route.params.transactionId}/status`,
          { status: "PROCESSING" }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction Processing",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const DeclineTransaction = async () => {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Enter Reason/Comment for declining",
        inputPlaceholder: "Type your reason/comment here...",
        inputAttributes: {
          "aria-label": "Type your reason/comment here",
        },
        showCancelButton: true,
      });

      if (!text) {
        return;
      }

      loading.value = true;
      try {
        const response = await apiPost(
          `/admin/cards/buy/transactions/${route.params.transactionId}/status`,
          { comments: text, status: "FAILED" }
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Declined Successfully",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to decline: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      ApproveTransaction,
      DeclineTransaction,
      ProcessTransaction,
      RequestConfirmation,
      route,
      data,
      loading,
    };
  },
});
</script>

<style scoped></style>
